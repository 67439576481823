<template>
    <div>
        <div class="header">
            <div @click="rechar">在线充值</div>
            <div class="shu"></div>
            <el-dropdown trigger="click" placement="bottom" v-if="station" @command="childChange">
                <span class="el-dropdown-link">
                    {{shop.name}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="item" v-for="item in shopList" :key="item.id">{{item.title}}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <div @click="goSettled" v-else>我要入驻开店</div>
            <div class="shu"></div>
            <div @click="goLogin" v-if="info == null">登录/注册</div>
            <el-dropdown trigger="click" placement="bottom" v-else @command="change">
                <span class="el-dropdown-link">
                    {{info.accountKey}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="item" v-for="item in classifyBaseList" :key="item.id">{{item.title}}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div class="head-title">
            <img src="../assets/images/home/header.png" alt="">
        </div>
        <div class="nav">
            <img src="../assets/images/logo.png" class="logo" alt="">
            <div class="menus">
                <div v-for="(item,index) in menusList" :key="item.id" :class="index ==current?'active':'' " @click="goPath(item,index)">{{item.title}}</div>
            </div>
        </div>
        <router-view />
        <Recharge v-if="rechargeShow" :dialogVisible="rechargeShow" @close="close" />
        <Complete @close="close" :show="show" />
    </div>
</template>

<script>
import Recharge from "../components/manage/recharge.vue";
import Complete from "../components/complete.vue";
export default {
    components: {
        Recharge,
        Complete
    },
    data() {
        return {
            current: 1,
            menusList: [
                { id: 0, title: '首页', path: '/home' },
                { id: 1, title: '服务中心', path: '/serve' },
                { id: 2, title: '数据广场', path: '/advancedQuery' },
                { id: 3, title: '我要发帖', path: '/community' },
                { id: 4, title: '用户管理', path: '/personOrder' },//setInfo
            ],
            classifyBaseList: [
                { id: 0, title: '我的资料', path: '/setInfo' },
                { id: 1, title: '我的订单', path: '/personOrder', },
                { id: 2, title: '我的钱包', path: '/wallet' },
                // { id: 3, title: '我的店铺', path: '/myShop', },
                { id: 4, title: '我的创作中心', path: '/myCreation', },
                { id: 5, title: '退出' },
            ],
            shopList: [
                { id: 0, title: '店铺管理', path: `/myShop` },
                { id: 1, title: '订单详情', path: '/orderDetail' },
                { id: 2, title: '信息编辑', path: '/editInfo' },
            ],
            rechargeShow: false,
            info: null,
            shop: null,
            token: false,
            show: false,
            station: false,
            curten: 1,
            tokens: ""
        };
    },
    watch: {
        $route: {
            handler: function (val, oldVal) {
                // console.log(val);
                if (localStorage.getItem("token")) {
                    this.token = true
                    this.getInfo()
                    this.getShop()
                }
                if (sessionStorage.getItem('current')) {
                    this.current = sessionStorage.getItem('current')
                }
            },
            deep: true
        }
    },
    mounted() {
        this.tokens = localStorage.getItem("token")
        if (localStorage.getItem("token")) {
            this.getInfo()
            this.token = true
        }
        this.getShop()
		let path = this.$route.path
		for(var item in this.menusList){
			if(this.$route.path == this.menusList[item].path){
				sessionStorage.setItem('current',this.menusList[item].id)
			}
			if(path.split('?')[0] == '/communityDetail'){
				sessionStorage.setItem('current','3')
			}
		}
        if (sessionStorage.getItem('current')) {
            this.current = sessionStorage.getItem('current')
        }

    },
    methods: {

        getInfo() {
            this.$util.post('/user/info').then(res => {
                console.log(res);
                this.info = res.data
                if (res.data.hasPayPsd) {
                    sessionStorage.setItem('payPsd', true)
                } else {
                    sessionStorage.setItem('payPsd', false)
                }

            })
        },
        getShop() {
            this.$util.post('/shop/myShop').then(res => {
                console.log(res.data);

                this.shop = res.data
                if (res.data.state !== undefined && !res.data.noReal) {
                    this.station = true
                }
            })
        },
        childChange(item) {
			if (sessionStorage.getItem("token") && sessionStorage.getItem('payPsd') == 'true') {
			    this.$router.push({ path: item.path })
			} else if (sessionStorage.getItem('payPsd') == 'false') {
			    return this.show = true
			
			}
			
			if (this.info.shopId == null) {
			    if (item.title == '店铺管理' || item.title == '订单详情' || item.title == '信息编辑') {
			        alert('你当前还没有店铺')
			        this.$router.push({ path: '/settled' })
			    }
			} else if (this.shop.state == 4 && !this.shop.noReal) {
			    this.$router.push({ path: item.path })
			} else if (this.shop && this.info.accountType == 1) {
			    if (item.title == '店铺管理' || item.title == '订单详情' || item.title == '信息编辑') {
			
			        if (this.shop.type == '1') {
			            this.$router.push({ path: '/personShop', query: { active: this.shop.state } })
			        } else {
			            this.$router.push({ path: '/enterShop', query: { active: this.shop.state } })
			        }
			    }
			
			} else if (this.shop && this.info.accountType == 2) {
			    if (item.title == '店铺管理' || item.title == '订单详情' || item.title == '信息编辑') {
			        if (this.shop.type == '1') {
			            this.$router.push({ path: '/personShop', query: { active: this.shop.state } })
			        } else {
			            this.$router.push({ path: '/enterShop', query: { active: this.shop.state } })
			        }
			    }
			} else if (!this.token) {
			    this.$message.error('请先进行登录')
			} else if (sessionStorage.getItem('payPsd') == 'false') {
			    this.show = true
			}
			else{
			    this.$router.push({ path: '/settled' })
			}
			
			sessionStorage.setItem("curten", 3)
			
			
            this.curten = 3
            // console.log('this.$a befor :>> ', this.$a);
            // this.$a = 3;
            // console.log('this.$a now :>> ', this.$a);
            sessionStorage.setItem('aaa', 3)
            // this.$router.push({ path: `${item.path}` })
			// if (this.token && sessionStorage.getItem('payPsd') == 'true') {
			//     if (this.shop && this.info.accountType == 1) {
			//         if (this.shop.type == '1') {
			//             this.$router.push({ path: '/personShop', query: { active: this.shop.state } })
			//         } else {
			//             this.$router.push({ path: '/enterShop', query: { active: this.shop.state } })
			//         }
			
			//     } else if (this.shop && this.info.accountType == 2) {
			//         if (this.shop.type == '1') {
			//             this.$router.push({ path: '/personShop', query: { active: this.shop.state } })
			//         } else {
			//             this.$router.push({ path: '/enterShop', query: { active: this.shop.state } })
			//         }
			//     } else {
			//         this.$router.push({ path: '/settled' })
			//     }
			// }
        },
        change(item) {
            // console.log(11);

            if (item.id == 5) {
                sessionStorage.removeItem("token");
                localStorage.removeItem("token")
                this.$router.go(0)
            } else {
                this.$router.push({ path: item.path })
            }
        },
        goPath(item, index) {
            this.current = index
            sessionStorage.setItem('current', index)
            this.$router.push({ path: item.path })
        },
        goLogin() {
            this.current = 0
            this.$router.push({ path: '/personalLogin' })
        },
        goSettled() {

            if (this.token && sessionStorage.getItem('payPsd') == 'true') {
                if (this.shop && this.info.accountType == 1) {
                    if (this.shop.type == '1') {
                        this.$router.push({ path: '/personShop', query: { active: this.shop.state } })
                    } else {
                        this.$router.push({ path: '/enterShop', query: { active: this.shop.state } })
                    }

                } else if (this.shop && this.info.accountType == 2) {
                    if (this.shop.type == '1') {
                        this.$router.push({ path: '/personShop', query: { active: this.shop.state } })
                    } else {
                        this.$router.push({ path: '/enterShop', query: { active: this.shop.state } })
                    }
                } else {
                    this.$router.push({ path: '/settled' })
                }
            } else if (!this.token) {

                this.$message.error('请先进行登录')
            } else if (sessionStorage.getItem('payPsd') == 'false') {
                this.show = true

            }
        },
        close() {
            this.rechargeShow = false
            this.show = false
        },
        rechar() {
            if (this.token && sessionStorage.getItem('payPsd') == 'true') {
                this.rechargeShow = true
            } else if (!this.token) {

                this.$message.error('请先进行登录')
            } else if (sessionStorage.getItem('payPsd') == 'false') {
                this.show = true

            }

        }

    }
};
</script>
<style lang="scss" scoped>
.header {
    padding: 15px 360px;
    background: #222222;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    color: #b9b9b9;
    .shu {
        width: 1px;
        height: 17px;
        background: #b9b9b9;
        margin: 0 40px;
    }
    div {
        cursor: pointer;
    }
    /deep/.el-dropdown {
        color: #b9b9b9;
    }
}
.head-title {
    height: 102px;
    background-color: #f6f6f6;
    padding: 6px 0 12px;
    img {
        width: 100%;
        height: 100%;
    }
}
.nav {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 20px auto 30px;
    .logo {
        width: 205px;
        height: 75px;
    }
    .menus {
        display: flex;
        align-items: flex-end;
        div {
            padding: 0 20px 11px;
            margin-left: 70px;
            font-size: 16px;
            color: #666666;
            font-weight: bold;
            cursor: pointer;
        }
        .active {
            color: #e40012;
            border-bottom: 2px solid #e40012;
        }
    }
}
</style>
