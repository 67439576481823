<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" width="1200px" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="handleClose">
            <div class="now">你当前正在</div>
            <el-steps :active="active" align-center>
                <el-step title="选择充值方式"></el-step>
                <el-step title="选择充值金额"></el-step>
                <el-step title="扫码支付"></el-step>
                <el-step title="充值完成"></el-step>
            </el-steps>
            <div class="state">
                <img src="../../assets/images/serve/gth.png" alt="" />
                正在 <span>充值</span>
            </div>
            <!-- 充值方式 -->
            <div v-show="active == 0">
                <div class="hint">*充值方式</div>
                <div class="payment">
                    <el-radio v-model="radio" :label="1" class="raido">
                        <div class="raido-info">
                            <div>
                                <img style="width: 22px; height: 22px" src="../../assets/images/serve/zfb.png" alt="" />
                                支付宝
                            </div>
                        </div>
                    </el-radio>
                </div>
                <div class="payment">
                    <el-radio v-model="radio" :label="2" class="raido">
                        <div class="raido-info">
                            <div>
                                <img style="width: 21px; height: 18px" src="../../assets/images/serve/wx.png" alt="" />
                                微信
                            </div>
                        </div>
                    </el-radio>
                </div>
                <div class="privacy">你的隐私正在保护中...</div>
                <!-- 底部按钮 -->
                <div>
                    <div class="foot-btn">
                        <!-- <el-button class="orders" @click="pay">付款</el-button>
                        <div class="give-up">申诉</div> -->
                        <el-button class="orders" @click="pay">下一步</el-button>
                    </div>
                    <div class="hui"></div>
                    <!-- <div class="hui">点击【付款后】款项在验收成果确认之前将由平台保管，待验收成功后将自动发往服务方。</div> -->
                    <!-- <div>若选择对公转账，可直接转账至以下账户（转账时备注 购买服务）；</div>
                    <div class="foot-content">
                        <div class="cell">
                            <div>公司名称:</div>
                        </div>
                        <div class="cell">
                            <div>银行账户:</div>
                        </div>
                        <div class="cell">
                            <div>开户行:</div>
                        </div>
                    </div>
                    <div style="color:#999">付款后将付款截图发给客服人员核实后可继续操作下一步</div> -->
                </div>
            </div>
            <!-- 选择充值金额 -->
            <div v-show="active == 1">
                <div class="hint">*充值方式</div>
                <div class="payment" v-if="radio == 1">
                    <el-radio v-model="radio" :label="1" class="raido">
                        <div class="raido-info">
                            <div>
                                <img style="width: 22px; height: 22px" src="../../assets/images/serve/zfb.png" alt="" />
                                支付宝
                            </div>
                        </div>
                    </el-radio>
                </div>
                <div class="payment" v-else>
                    <el-radio v-model="radio" :label="2" class="raido">
                        <div class="raido-info">
                            <div>
                                <img style="width: 21px; height: 18px" src="../../assets/images/serve/wx.png" alt="" />
                                微信
                            </div>
                        </div>
                    </el-radio>
                </div>
                <div class="hint">*充值金额</div>
                <div class="backJd">
                    <div class="moengt">
                        ￥ <input style="width: 50px" v-model="money" type="number" />
                    </div>
                    <div style="margin-left: 20px">最少充值金额：1元</div>
                </div>
                <div class="privacy">你的隐私正在保护中...</div>
                <!-- 底部按钮 -->
                <div class="foot-btn">
                    <div @click="pre">返回上一步</div>
                    <el-button class="orders" @click="recharge">下一步</el-button>
                </div>
            </div>
            <!-- 扫码支付 -->
            <div v-if="active == 2">
                <div class="hint">*充值方式</div>
                <div class="payment" v-if="radio == 1">
                    <el-radio v-model="radio" :label="1" class="raido">
                        <div class="raido-info">
                            <div>
                                <img style="width: 22px; height: 22px" src="../../assets/images/serve/zfb.png" alt="" />
                                支付宝
                            </div>
                        </div>
                    </el-radio>
                </div>
                <div class="payment" v-else>
                    <el-radio v-model="radio" :label="2" class="raido">
                        <div class="raido-info">
                            <div>
                                <img style="width: 21px; height: 18px" src="../../assets/images/serve/wx.png" alt="" />
                                微信
                            </div>
                        </div>
                    </el-radio>
                </div>
                <div class="hint">*充值金额</div>
                <div class="backJd">
                    <div class="moengt red">
                        <!-- ￥ <input style="width: 50px;" type="number"> -->
                        {{ money }}元
                    </div>
                </div>
                <!-- 底部按钮 -->
                <div class="foot-btn">
                    <div @click="pre">返回上一步</div>
                    <div class="qr-code" ref="qrCodeUrl"></div>
                </div>
            </div>
            <!-- 充值完成 -->
            <div v-show="active == 3">
                <div class="no-win">
                    <img src="../../assets/images/serve/qr.png" alt="" />
                    充值成功！已充值{{ money }}元，可前往<span class="red" @click="seeMyWallet">我的钱包</span>查看余额
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import QRCode from "qrcodejs2";
import Code from "../../components/code.vue";
export default {
    components: { Code },
    props: {
        dialogVisible: Boolean,
    },

    data() {
        return {
            code: "",
            money: "",
            id: null,
            active: 0,
            radio: 1, //zhifu
            state: "0", //  0 未支付 1支付超时 2支付成功
        };
    },

    mounted() { },

    methods: {
        seeMyWallet() {
            this.$emit("close");
            this.$router.push({ path: "/wallet" });
        },
        pre() {
            this.active--;
        },
        handleChange(value) {
            console.log(value);
        },
        handleClose(done) {
            this.state = 3;
            this.$emit("close");
        },
        // 充值
        recharge() {
            this.$util
                .post("/center/myOrder/recharge", {
                    money: this.money,
                    payType: this.radio,
                })
                .then((res) => {
                    console.log(res);
                    if (res.code == 200) {
                        this.active++;
                        this.code = res.data.code;
                        this.id = res.data.id;
                        this.$nextTick(() => {
                            this.creatQrCode();
                        });
                        let timmer = setInterval(() => {
                            if (this.state == "0") {
                                this.payState();
                            } else {
                                clearInterval(timmer);
                                if (this.state == 1) {
                                    this.active = 1;
                                    this.$emit("close");
                                    return this.$message.error("支付超时");
                                } else if (this.state == 2) {
                                    this.active = 3;
                                }
                            }
                        }, 2000);
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },
        creatQrCode() {
            var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                text: this.code,
                width: 257,
                height: 257,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H,
            });
        },
        // 获取支付状态
        payState() {
            this.$util
                .post("/center/myOrder/getRecordState", {
                    id: this.id,
                })
                .then((res) => {
                    console.log(res);
                    this.state = res.data.state;
                });
        },
        // 付款
        pay() {
            if (this.active++ > 4) this.active = 0;
        },
    },
};
</script>

<style lang="scss" scoped>
// 弹出框

.now {
    font-size: 18px;
    color: #999999;
    margin-bottom: 30px;
}
/deep/.el-step__title.is-wait {
    // background: #e40012;
    color: #666;
}
/deep/.el-step__title.is-process {
    color: #e40012;
}
/deep/.el-step__icon.is-text {
    color: #666666;
    width: 36px;
    height: 36px;
}
/deep/.el-step__head.is-process {
    border-color: #e40012;
}
/deep/.el-step__title.is-finish {
    color: #e40012;
}
/deep/.el-step__head.is-finish {
    border-color: #e40012;
}
.red {
    color: #e40012 !important;
    cursor: pointer;
}
.look {
    display: flex;
    align-items: center;
    color: #222;
    font-size: 16px;
    margin: 40px 0;
    div {
        color: #999999;
        margin-right: 20px;
    }
}
.blue {
    color: #2ea7e0;
    font-size: 12px;
    // margin-top: 10px ;
    margin-bottom: 30px;
}
.checked {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666666;
    span {
        color: #2ea7e0;
    }
}
.state {
    padding: 20px 0 10px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #333333;
    span {
        color: #e40012;
    }
    img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
}
// /deep/.el-input__inner {
//     width: 490px;
// }
.code {
    height: 48px;
    // border: 1px solid #dcdfe6;
    display: flex;
    align-items: center;
    // width: 500px;
    input {
        height: 100%;
        // border: none;
        outline: none;
        padding: 0 15px;
        border-radius: 4px;
        font-size: 16px;
        border: 1px solid #eeeeee;
    }
    button {
        background-color: transparent;
        border: none;
        color: #e40012;
        font-weight: bold;
        font-size: 14px;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}
input::-webkit-input-placeholder {
    color: #c0c4cc;
}
input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #c0c4cc;
}
input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #c0c4cc;
}
input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #c0c4cc;
}
.verification {
    display: flex;
    margin-top: 10px;
    align-items: center;
    .text {
        font-size: 14px;
        color: #666666;
        cursor: pointer;
        margin-left: 10px;
        span {
            color: #e40012;
        }
    }
}
.privacy {
    font-size: 16px;
    color: #00a71c;
    margin: 30px 0 40px;
}
.hint {
    font-size: 16px;
    color: #999999;
    margin: 30px 0;
}
.ysmoney {
    padding: 15px 60px;
    border: 1px solid #eeeeee;
    color: #e40012;
    font-size: 16px;
    // text-align: center;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
}
.payment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    padding: 15px 30px;
    border: 1px solid #eeeeee;
    margin-bottom: 20px;
    color: #999;
}
.raido {
    display: flex;
    align-items: center;
}
.raido-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-left: 10px;
    align-items: center;
    color: #222;
    div {
        display: flex;
        align-items: center;
    }
    img {
        margin-right: 10px;
        // margin-top: ;
    }
    span {
        color: #999999;
        font-size: 14px;
        margin-left: 10px;
    }
}
.deposit {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #222;
    span {
        color: #e40012;
    }
    .price {
        padding: 15px 48px;
        border: 1px solid #eeeeee;
        margin-right: 20px;
        text-align: center;
        div {
            color: #999999;
            font-size: 14px;
            margin-top: 10px;
        }
    }
}
.paypass {
    display: flex;
    align-items: center;
    input {
        width: 64px;
        height: 64px;
        border: 1px solid #d1d1d1;
        outline: none;
        text-align: center;
        line-height: 64px;
        font-size: 60px;
        margin-right: 20px;
    }
}
::-ms-clear {
    display: none;
}

::-ms-reveal {
    display: none;
}

.orderInformation {
    padding: 20px;
    border: 1px solid #eeeeee;
    margin: 30px 0 30px;
    .cell {
        display: flex;
        font-size: 16px;
        color: #999999;
        margin-bottom: 10px;
        div {
            width: 100px;
        }
        span {
            color: #222222;
            margin-left: 65px;
        }
        .blue {
            font-size: 16px;
            color: #2ea7e0;
        }
    }
}
.accept {
    display: flex;
    font-size: 16px;
    color: #999999;
    span {
        margin-top: 15px;
    }
    div {
        flex: 1;
        height: 160px;
        border: 1px solid #eeeeee;
        padding: 15px 10px;
        margin-left: 20px;
    }
}
.accepting {
    padding: 20px;
    border: 1px solid #eeeeee;
    .cell {
        font-size: 16px;
        color: #222222;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        div {
            width: 100px;
            color: #999999;
            margin-right: 50px;
        }
        span {
            color: #e40012;
        }
    }
}
.wait {
    font-size: 18px;
    color: #999999;
    text-align: center;
    margin-top: 30px;
}
.wait-btn {
    padding: 13px 29px;
    background: #d1d1d1 !important;
}
.give-up {
    margin-left: 80px;
    font-size: 18px;
    color: #e40012;
}
.no-win {
    text-align: center;
    font-size: 18px;
    color: #999999;
    margin-top: 150px;
    margin-bottom: 70px;
    span {
        color: #e40012;
    }
    img {
        width: 56px;
        height: 56px;
        display: block;
        margin: 0 auto 20px;
    }
}
.time-out {
    display: flex;
    align-items: center;
    margin: 100px 0;
    color: #ffcc00;
    font-size: 18px;
    justify-content: center;
    img {
        width: 38px;
        height: 32px;
        margin-right: 20px;
    }
}
.foot-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 90px;
    cursor: pointer;
    font-size: 16px;
    color: #e40012;
}
.orders {
    // margin-top: 90px;
    // width: 247px;
    // height: 50px;
    padding: 13px 100px;
    background: #e40012;
    color: #fff;
    margin-left: 30px;
    font-size: 18px;
    border-radius: 4px;
    // margin: 90px 0 0;
}
.upload {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 30px 0;
    color: #999999;
    .lable {
        margin-right: 20px;
    }
}
/deep/.el-upload--picture-card {
    width: 90px;
    height: 90px;
    line-height: 100px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
    width: 90px;
    height: 90px;
}
/deep/.el-form-item__label {
    line-height: normal;
}
.l-hint {
    div {
        color: #2ea7e0;
        margin-bottom: 10px;
    }
    font-size: 14px;
    color: #222222;
}
.hui {
    text-align: center;
    font-size: 14px;
    color: #999999;
    margin-top: 20px;
    margin-bottom: 30px;
}
.tags {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    line-height: normal !important;
    div {
        padding: 6px 14px;
        border: 1px solid #dbdbdb;
        border-radius: 4px;
        margin-right: 10px;
        // margin-bottom: 10px;
        cursor: pointer;
    }
    .active {
        padding: 6px 14px;
        border: 1px solid #e40012;
        color: #e40012;
    }
}
.moengt {
    display: flex;
    align-items: center;
    border: 1px solid #eee;
    color: #999999;
    padding: 15px 10px;
    width: 200px;
    input {
        flex: 1;
        padding-left: 10px;
        font-size: 16px;
        border: none;
        outline: none;
    }
}
.backJd {
    margin: 20px 0;
    color: #222;
    font-size: 14px;
    display: flex;
    align-items: center;
}
.reason {
    display: flex;
    color: #222;
    align-items: center;
    margin-bottom: 20px;
    span {
        margin-right: 10px;
        color: #999999;
        font-size: 16px;
    }
}
.foot-content {
    margin: 20px 0;
    .cell {
        color: #222222;
        font-size: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        div {
            width: 100px;
            color: #999999;
            margin-right: 50px;
        }
    }
}
.qr-code {
    width: 257px;
    height: 257px;
    // margin: 40px auto;
    margin-left: 30px;
    // background: red;
    img {
        width: 100%;
        height: 100%;
    }
}
.evaluation {
    padding: 20px 0;
    border-bottom: 1px solid #eeeeee;
    font-size: 22px;
    color: #222;
}
.evaluation-info {
    margin: 30px 0 50px;
    font-size: 16px;
    .cell {
        display: flex;
        color: #222;
        align-items: center;
        margin-bottom: 30px;
        .title {
            width: 100px;
            color: #999;
            margin-right: 20px;
        }
    }
}
</style>
